// MissionVisionPage.js

import React from 'react';
import './MissionVisionPage.css'; // Import the CSS file for styling

import vissionImage from '../../assets/vission1.png'; // Replace with actual path
   import missionImage from '../../assets/mission.png';
const MissionVisionPage = () => {
  return (
    <div className="mission-vision-container" id='missionId'>
      <div className="section mission-section">
        <h2>Our Mission</h2>
        
        <p>
          At IT Infra Solutions, it’s our commitment and mission to ensure our
          customers' success and satisfaction in the Internet world by delivering
          the best products, services, and technologies. We strive to develop a
          long-term business relationship with our customers through a
          "Right-on-Time" delivery system. We will partner with our clients to
          deliver solutions and technologies to their satisfaction and assist
          everyone to realize their full potential.
        </p>
        <img src={missionImage} alt="Mission" />
      </div>

      
    </div>
  );
};

export default MissionVisionPage;
