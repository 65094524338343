import React from "react";
import ai from "../../assets/back1.png";
import "./header.css";

const Header = () => (
  <div className="gpt3__header section__padding" id="homeId">
    <div className="gpt3__header-content">
      <h1>
        Welcome to <br /> IT Infra Solutions
      </h1>
      <p>
        The-Citrix,TSE & RDS alternative Ensure Business Continuity with <br />
        <b>ConnectApp</b>
      </p>
    </div>

    <div className="gpt3__header-image">
      <img src={ai} alt="iis" />
    </div>
  </div>
);

export default Header;
