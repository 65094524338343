import React from "react";
import connectlogo from "../../assets/connectlogo.png";
import "./overview.css";
import { FaTruck } from "react-icons/fa";

import { CiGlobe } from "react-icons/ci";

import { VscRemoteExplorer } from "react-icons/vsc";
import { FaEdgeLegacy } from "react-icons/fa6";
import { FaHandHoldingDollar } from "react-icons/fa6";
import { MdOutlineSecurity } from "react-icons/md";

function Overview() {
  return (
    <div className="overview__container" id="overviewId">
      <div className="main__overview">
        <div className="main__overview__brief">
          <h3>
            A concise overview of the <span id="co">Connect</span>{" "}
            <span id="app">App</span>{" "}
          </h3>
          <hr />
          <hr />
          <br />
          <br />

          <h2>
            <span id="co">Connect</span> <span id="app">App</span> Product
            Licenses
          </h2>
          <p>
            Connect App offers various editions with distinct features, ready
            for easy installations. The initial editions, namely System and
            Printer editions, mandate Client generation and subsequent
            installation on Client Machines. On the other hand, the Web/Mobile
            edition and Enterprise editions offer remarkable fle-ibility to
            users, enabling them to utilize different devices at the client end.
            This fle-ibility stems from the capability of these editions to
            publish Server Applications through web browsers. Following are the
            details of the Connect App Product Editions.
          </p>
        </div>
        <img src={connectlogo} alt="" />
      </div>
      <div class="overview_inner_header_container">
        <i class="fab fa-product-hunt"> </i>
        <h3>
          <span id="co">Connect</span> <span id="app">App</span> Product
          Licenses
        </h3>
      </div>
      <div class="overview__inner__system ">
        <div class="time_line_icon">
          <i class="fas fa-desktop"></i>
        </div>
        <h2>System Edition</h2>
        <p>
          The System edition facilitates access to Windows-based machines,
          including operating systems such as Windows 7, 8, 10, 11, or Windows
          Server 2008 to 2019. It efficiently manages concurrent remote users,
          allowing them to connect to the central system in a manner akin to
          Citri- or Terminal Server. The Client generator plays a crucial role
          in tailoring Remote Access configurations to meet specific needs.
        </p>
      </div>
      <div class="overview__inner__system ">
        <div class="time_line_icon">
          <i class="fa-solid fa-print"></i>
        </div>
        <h2>Printer Edition</h2>
        <p>
          Similar to the System edition, this variant of the Connect App
          incorporates an additional feature known as the 'Universal Printer.'
          This addition brings a distinctive flavor to the functionality,
          offering a unique capability to produce printouts from any remote
          location when connected to the Central Server.
        </p>
      </div>
      <div class="overview__inner__system ">
        <div class="time_line_icon">
          <i class="fa-solid fa-globe"></i>
        </div>
        <h2>Web & Mobile Edition</h2>
        <p>
          The introduction of the Web & Mobile Edition by Connect App signifies
          a breakthrough in technology, allowing the publishing of applications
          and system access via a web browser. This edition incorporates a
          Secure-web tunnel, presenting a unique facility where users can
          connect to the server from any web browser using SSH tunneling and
          port forwarding over HTTP and HTTPS. Notably, all communications in
          this Connect App edition are end-to-end encrypted, eliminating the
          need for a VPN.
        </p>
      </div>
      <div class="overview__inner__system ">
        <div class="time_line_icon">
          <i class="fa-solid fa-shield-halved"></i>
        </div>
        <h2>Enterprise Edition</h2>
        <p>
          Beyond the comprehensive features inherent in the Web & Mobile
          Edition, the Connect App's Enterprise edition caters to the unique
          needs of corporate clients. Notably, this edition goes the e-tra mile
          by incorporating a Gateway portal and Load Balancing functionality,
          ensuring a robust and tailored solution for the distinct requirements
          of corporate customers.
        </p>
      </div>
      <div className="overview__inner_Enterprizeplus">
        <div class="overview__inner__Enterprizebox">
          <div class="time_line_icon">
            <i class="fa-solid fa-shield-halved"></i>
          </div>
          <h2>Enterprise PLUS Edition</h2>
          <ol type="I">
            <li>
              The Enterprise PLUS edition represents a comprehensive bundle of
              products, inclusive of the Connect App Companion Tools. Enterprise
              PLUS Includes:
            </li>
            <li> Connect App Enterprise Edition with unlimited user access</li>
            <li>Advanced Security Features </li>
            <li>2FA (Two-Factor Authentication)</li>
            <li>Server Genius</li>
            <li>Virtual Printer</li>
          </ol>
        </div>
      </div>
      <h2 id="compare">Compare Between Connect App Editions:</h2>
      <div className="overview__comparision__edition">
        <div class="col-md-12">
          <div class="scrolltable">
            <table>
              <tbody>
                <tr>
                  <th>Features</th>
                  <th>System</th>
                  <th>Printer</th>
                  <th>Mobile Web</th>
                  <th>Enterprise</th>
                  <th>Enterprise PLUS</th>
                </tr>
                <tr>
                  <td>ConnectApp Administrator Tool (AdminTool)</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <td>Concurrent connections support </td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <td>Application Control per user and/or per groups </td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <td>ConnectApp Remote Taskbar / Floating Panel </td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <td>Remote Desktop access </td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <td>ConnectApp Portable Client Generator </td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <td>Seamless and RemoteApp connection clients </td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <td>Fully compliant with RDP protocol </td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <td>
                    Dual Screen support, bi-directional Sound, RemoteF- when
                    compatible with Windows version{" "}
                  </td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <td>Local and Remote connection support </td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <td>Workgroup and Active Directory users support </td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <td>
                    Universal Printer: print from any location, without having
                    to install any printer driver{" "}
                  </td>
                  <td>X </td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <td>
                    Connection from any web browser with ConnectApp HTTP Web
                    Server{" "}
                  </td>
                  <td>X </td>
                  <td>X </td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <td>
                    Secured connection from any web browser with ConnectApp
                    HTTPS Web Server and SSH server{" "}
                  </td>
                  <td>X </td>
                  <td>X </td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <td>Easy connection from iPhone/iPad and Android devices </td>
                  <td>X </td>
                  <td>X </td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <td>
                    Customizable logon web page with ConnectApp Webmaster
                    Toolkit{" "}
                  </td>
                  <td>X </td>
                  <td>X </td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <td>
                    With ConnectApp Web Applications Portal, access your
                    applications list inside any web browser{" "}
                  </td>
                  <td>X </td>
                  <td>X </td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <td>
                    Connect with just an eX mail or a pinX code with ConnectApp
                    Web Credentials{" "}
                  </td>
                  <td>X </td>
                  <td>X </td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <td>
                    Connect on your Web Portal with ConnectApp Web App on any
                    device{" "}
                  </td>
                  <td>X </td>
                  <td>X </td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <td>ConnectApp farm of servers (one license per server) </td>
                  <td>X </td>
                  <td>X </td>
                  <td>X </td>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <td>
                    Unlimited number of users working concurrently on a scalable
                    LoadX Balanced architecture{" "}
                  </td>
                  <td>X </td>
                  <td>X </td>
                  <td>X </td>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <td>
                    Single Enterprise Portal to access all your ConnectApp
                    servers{" "}
                  </td>
                  <td>X </td>
                  <td>X </td>
                  <td>X </td>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <td>
                    Ability to assign one or several Application Server(s) to
                    users or groups{" "}
                  </td>
                  <td>X </td>
                  <td>X </td>
                  <td>X </td>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <td>Load Balancing and Failover support </td>
                  <td>X </td>
                  <td>X </td>
                  <td>X </td>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <td>ConnectApp Advanced Security: Secure your RDS Server </td>
                  <td>X </td>
                  <td>X </td>
                  <td>X </td>
                  <td>X </td>
                  <td>✓</td>
                </tr>
                <tr>
                  <td>Server Genius: Monitor your servers and websites </td>
                  <td>X </td>
                  <td>X </td>
                  <td>X </td>
                  <td>X </td>
                  <td>✓</td>
                </tr>
                <tr>
                  <td>
                    ConnectApp Two-Factor Authentication: Add an e-tra layer of
                    security{" "}
                  </td>
                  <td>X </td>
                  <td>X </td>
                  <td>X </td>
                  <td>X </td>
                  <td>✓</td>
                </tr>
                <tr>
                  <td>
                    ConnectApp Virtual Printer: Enhance your remote printing{" "}
                  </td>
                  <td>X </td>
                  <td>X </td>
                  <td>X </td>
                  <td>X </td>
                  <td>✓</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="overview_inner_header_container">
        <i class="fab fa-product-hunt"> </i>
        <h3>
          <span id="co">Connect</span> <span id="app">App</span> Support
          Licenses
        </h3>
      </div>
      <div class="overview__inner__system ">
        <div class="time_line_icon">
          <i class="fa-solid fa-key"></i>
        </div>
        <h2>Connect App Support Licenses </h2>
        <p>
          The user needs to purchase Connect App support licenses in order to
          avail the benefits such as New version updates, New product updates.
          Moreover, it is mandatory that the Support licenses should be valid in
          order to rehost the Product license required due to change of machine,
          formatting of O/S etc. Notably, the quantity of Connect App Support
          licenses must align with the number of product licenses installed on
          the respective machine
        </p>
      </div>
      <div className="whyConnect">
        <div className="home_para_why_connect">
          <p>THE IDEAL ALTERNATIVE TO CITRIX AND RDS</p>
        </div>
        <div className="home_heading_why_connect">
          <p className="home_paragraph__why__connect">
            Why <span id="co">Connect</span> <span id="app">App</span> Remote
            Access?
          </p>
        </div>
        <div class="box__containers_why_connect">
          <div class="boxs_why_connect">
            <div className="icons_why_connect">
              <VscRemoteExplorer />
            </div>
            <h2>Remote Desktop Access </h2>
            <p>
              Create full desktops for users to remotely access centralized apps
              from anywhere and any device.
            </p>
          </div>
          <div class="boxs_why_connect">
            <div className="icons_why_connect">
              {" "}
              <CiGlobe />
            </div>
            <h2>Create a SaaS solution</h2>
            <p>
              Transform your applications into a Software as a Service (SaaS)
              solution by making them accessible via the web. Provide clients
              with controlled access and additional customization options.
            </p>
          </div>
          <div class="boxs_why_connect">
            <div className="icons_why_connect">
              <FaTruck />
            </div>
            <h2>Deliver applications locally</h2>
            <p>
              Deploy your Windows applications to ensure seamless accessibility
              on the remote devices of your users.
            </p>
          </div>
        </div>
        <div class="box__containers_why_connect">
          <div class="boxs_why_connect">
            <div className="icons_why_connect">
              <FaEdgeLegacy />
            </div>
            <h2>Web-enable legacy Apps </h2>
            <p>
              Modernize legacy applications by enabling web access without the
              need for redevelopment, preserving your current user interface and
              ensuring client satisfaction.
            </p>
          </div>
          <div class="boxs_why_connect">
            <div className="icons_why_connect">
              {" "}
              <FaHandHoldingDollar />
            </div>
            <h2>Optimize your IT budget</h2>
            <p>
              Reduce your IT expenses by avoiding the need for app redevelopment
              or costly alternative purchases. Benefit from lifetime permanent
              licenses, saving on long-term costs.
            </p>
          </div>
          <div class="boxs_why_connect">
            <div className="icons_why_connect">
              <MdOutlineSecurity />
            </div>
            <h2>Secure your infrastructure</h2>
            <p>
              Secure your remote connections with SSL encryption. Enhance your
              remote infrastructure security by incorporating
              ConnectApp-Ultimate Security and ConnectApp 2FA for maximum
              protection.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Overview;
