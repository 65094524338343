import React, { useState, useEffect } from "react";

import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import { Link, NavLink } from "react-router-dom"; // Import NavLink
import { IoIosCall } from "react-icons/io";
import logo from "../../assets/logo.png";
import "./navbar.css";
import axios from "axios";
import FileDownload from "js-file-download";
import { FaCaretDown } from "react-icons/fa";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  useEffect(() => {
    // Use DOMContentLoaded event to ensure the DOM is fully loaded
    const navbar = document.querySelector(".gpt3__navbar");
    if (navbar) {
      window.addEventListener("scroll", () => {
        // Check the scroll position
        if (window.scrollY > 0) {
          // Add the 'scrolled' class when scrolling
          navbar.classList.add("scrolled");
        } else {
          // Remove the 'scrolled' class when at the top
          navbar.classList.remove("scrolled");
        }
      });
    }
  }, []);

  const closeMenu = () => {
    setToggleMenu(false);
  };
  const download = (e) => {
    e.preventDefault();
    axios({
      url: "https://dev.itinfrasolutions.co.in/", // make sure the endpoint is correct
      method: "GET",
      responseType: "blob", // important to set response type as blob
    })
      .then((res) => {
        FileDownload(res.data, "Setup_connectApp.exe");
      })
      .catch((error) => {
        console.error("Download error:", error);
        alert("There was an issue downloading the file. Please try again.");
      });
  };
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);

    if (section) {
      window.scrollTo({
        top:
          section.offsetTop -
          document.querySelector(".gpt3__navbar").offsetHeight,
        behavior: "smooth",
      });

      closeMenu(); // Close the menu after clicking on a menu item
    }
  };

  return (
    <div class="main__container">
      <div class="container ">
        <div class="top-inner">
          <ul class="list-inline">
            <li class="list-inline-item">
              <a href="tel:91 8468839026">
                {" "}
                <i class="#">
                  <IoIosCall />
                </i>{" "}
                +91 8468839026
              </a>
            </li>

            <li class="list-inline-item-mail">
              <a href="mailto:sales@itinfrasolutions.co.in">
                <i class="fa-regular fa-envelope"></i>{" "}
                sales@itinfrasolutions.co.in
              </a>
            </li>
          </ul>
        </div>

        <div class="top-inner-right">
          <ul class="list-inline__what">
            <li class="list-inline-item__what">
              <a href="/contactUs">
                <i class="fa-solid fa-headset"></i>Get Support
              </a>
            </li>
            <li class="list-inline-item ">
              <a href="https://wa.me/918468839026 " target="__blank">
                <i class="fa-brands fa-whatsapp"></i> WhatsApp
              </a>
            </li>
          </ul>
        </div>
      </div>

      <header className="gpt3__navbar">
        <div className="gpt3__navbar-links">
          <div className="gpt3__navbar-links_logo">
            <a href="/">
              {" "}
              <img src={logo} alt="IIS" />
            </a>
          </div>
          <ul type="none" className="gpt3__navbar-links_container">
            <li>
              <NavLink to="/" onClick={() => scrollToSection("homeId")}>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/services"
                onClick={() => scrollToSection("serviceId")}
              >
                Services
              </NavLink>
            </li>
            <li>
              {/* <NavLink to="#connectApp">Connect App</NavLink> */}
              <div class="dropdown">
                <NavLink to="#">
                  Connect App <FaCaretDown />
                </NavLink>

                <div class="dropdown-content">
                  <NavLink
                    to="/connectApp"
                    onClick={() => scrollToSection("overviewId")}
                  >
                    ConnectApp Overview
                  </NavLink>
                  <NavLink
                    to="/features"
                    onClick={() => scrollToSection("featuresId")}
                  >
                    ConnectApp Features
                  </NavLink>
                  <NavLink
                    to="/comparisonSheet"
                    onClick={() => scrollToSection("comparisionId")}
                  >
                    ConnectApp comparison sheet
                  </NavLink>
                  <NavLink onClick={(e) => download(e)}>
                    ConnectApp Download
                  </NavLink>
                </div>
              </div>
            </li>
            <li>
              <div class="dropdown">
                <NavLink to="#">
                  About <FaCaretDown />
                </NavLink>

                <div class="dropdown-content">
                  <NavLink
                    to="/about"
                    onClick={() => scrollToSection("introId")}
                  >
                    Introduction
                  </NavLink>
                  <NavLink
                    to="/mission"
                    onClick={() => scrollToSection("missionId")}
                  >
                    Mission
                  </NavLink>
                  <NavLink
                    to="/vission"
                    onClick={() => scrollToSection("visionId")}
                  >
                    Vission
                  </NavLink>
                </div>
              </div>
            </li>
            <li>
              <NavLink
                to="/contactUs"
                onClick={() => scrollToSection("contactUsId")}
              >
                Contact Us
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="gpt3__navbar-menu">
          {toggleMenu ? (
            <RiCloseLine color="#FF0000" size={27} onClick={closeMenu} />
          ) : (
            <RiMenu3Line
              color="#FF0000"
              size={27}
              onClick={() => setToggleMenu(true)}
            />
          )}
          {toggleMenu && (
            <div className="gpt3__navbar-menu_container ">
              <ul type="none" className="gpt3__navbar-menu_container-links">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/services">Services</a>
                </li>
                <li>
                  {/* <NavLink to="#connectApp">Connect App</NavLink> */}
                  <div class="dropdown">
                    <a href="#">
                      Connect App <FaCaretDown />
                    </a>

                    <div class="dropdown-content">
                      <a href="/connectApp">ConnectApp Overview</a>
                      <a href="/features">ConnectApp Features</a>
                      <a href="/comparisonSheet">ConnectApp comparison sheet</a>
                      <a onClick={(e) => download(e)} href="/setup_fle_download">ConnectApp Download</a>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="dropdown">
                    <a href="#">
                      About <FaCaretDown />
                    </a>

                    <div class="dropdown-content">
                      <a href="/about">Introduction</a>

                      <a href="/mission">Mission</a>
                      <a href="/vission">Vission</a>
                    </div>
                  </div>
                </li>
                <li>
                  <a href="/contactUs">Contact Us</a>
                </li>
              </ul>
            </div>
          )}
        </div>
      </header>
    </div>
  );
};

export default Navbar;
