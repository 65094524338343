import React from "react";
import logo from "../../assets/logo.png";
import "./footer.css";
import { FaSquareFacebook } from "react-icons/fa6";
import { FaSquareInstagram } from "react-icons/fa6";
import { BsLinkedin } from "react-icons/bs";
import { FaSquareXTwitter } from "react-icons/fa6";
// import { FaYoutube } from "react-icons/fa";
import { FaSquareSnapchat } from "react-icons/fa6";



const Footer = () => (
  <footer className="bottom">
    <div className="gpt3__footer">
      <div className="gpt3__footer-links">
        <div className="gpt3__footer-links_logo">
          <img src={logo} alt="It infra" />
          <p>
            <b>IT Infra Solutions </b>is a pioneering force since inception,
            specializing in I.T. Solutions & Services. Renowned for offering ERP
            Solutions, Firewall, Remote Software, VPN Product, Server Hardware,
            Mailing, Cloud Server, and APP Server on the cloud. Partnering with
            industry giants like IBM, HP, and WIPRO ensures top-notch quality.
            Our expertise in firewall software, including ZYXEL, SONICWALL, and
            JUPITOR, provides comprehensive network security. Dedicated to
            excellence, we guarantee certainty and add significant business
            value to organizations through qualitative services and products.
          </p>
        </div>
        <div className="gpt3__footer-links_div">
          <h4>Links</h4>
          <p>Overons</p>
          <p>Social Media</p>
          <p>Counters</p>
          <p>Contact</p>
        </div>
        <div className="gpt3__footer-links_div">
          <h4>Company</h4>
          <p>Terms & Conditions </p>
          <p>Privacy Policy</p>
          <p>Contact</p>
        </div>
        <div className="gpt3__footer-links_div">
          <h4>Get in touch</h4>
          
          <hr />
          <br />
          <i class="fa-solid fa-location-dot"></i>
          <p>
            A- 48/2, Mahavir Enclave, Near Dwarka Puri Bus Stand NewDelhi-110059
          </p>
          <i class="fas fa-phone-alt" aria-hidden="true"></i>
          <p>+919015739026</p>
          <i class="fa fa-envelope" aria-hidden="true"></i>
          <p>sk@itinfrasolutions.co.in</p>
          {/* <br />
          <h4>2:Gorakhpur Office</h4>
          <hr />
          <br />
          <FaLocationArrow />
          <p>
            A- 48/2, Mahavir Enclave, Near Dwarka puri bus stand NewDelhi-110059
          </p>
          <FaMobileRetro />
          <p>+919015739026</p>
          <FaEnvelope />
          <p>sk@itinfrasolutions.co.in</p> */}
        </div>
        </div>


        <div>
          <div className="gpt3__footer-links_div__social">
            <h4>Follows Us</h4>
          
          {/* <div className="gpt3__footer-links_social"> */}
            <a href="https://www.google.com/" target="_blank">
              <FaSquareInstagram className=" text-3xl instagram" />
            </a>
            <a href="#">
              <FaSquareFacebook className="text-3xl facebook" />
            </a>
            <a href="https://www.linkedin.com/in/abhi302/" target="_blank">
              <BsLinkedin className="text-3xl linkedin" />
            </a>
            <a href="#">
              <FaSquareXTwitter className="text-3xl twitter" />
            </a>
            <a href="#">
              <FaSquareSnapchat className="text-3xl snapchat " />

            </a>
          {/* </div> */}
          </div>
        </div>
      {/* </div> */}
    </div>
    <div className="footer__outer">
      <div class="footer">
        <div class="footer-visitor">
          {/* <strong>Visitors:</strong> */}
        </div>
        <div class="footer-copyright">
          Copyright © It Infra Solutions. All Rights Reserved.
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
