import React from "react";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import Navbar from "./navbar/Navbar";
import { Outlet,useLocation } from "react-router-dom";
import "../../src/App.css";
import Home from "./Home/Home";

function Layout() {
  const location = useLocation();

  // Define an array of route paths where Home and Header should not be visible
  const hiddenRoutes = ["/about", "/contact","/services","/vission","/mission","/connectApp","/features","/comparisonSheet","/contactUs"]; // Add more paths as needed

  // Check if the current route is in the hiddenRoutes array
  const isHiddenRoute = hiddenRoutes.includes(location.pathname);
  return (
    <>
      <Navbar />
      {!isHiddenRoute && <Header />}
      {!isHiddenRoute && <Home />}
      {/* <Header /> */}

      {/* <Home /> */}
      <Outlet />

      <Footer />
    </>
  );
}

export default Layout;
