import React from "react";
import "./comparison.css";

function ComparisonSheet() {
  return (
    <div>
      <div className="ComparisionSheet" id="comparisionId">
        <div className="comparison">
          <h2>
            Connect <span id="app">App</span> vs. RDS and Citrix
          </h2>
          <p>
            If you want a quick and straightforward way to make your
            applications accessible on the web, <span id="co">Connect</span>{" "}
            <span id="app">App</span> is the top pick.{" "}
            <span id="co">Connect</span> <span id="app">App</span> offers a
            speedy, easy, and budget-friendly solution to get your application
            online. It's a simple and hassle-free way to publish your
            application on the Internet.
          </p>
        </div>
        
      </div>
      <div className="table__container">
      <div className="scroll">
          <table>
            <tbody>
              <tr id="tableHeading">
                <th>Features & Benefits</th>
                <th>RDS ®</th>
                <th>Citrix ®</th>
                <th>Connect App ®</th>
              </tr>
              <tr>
                <th>REMOTE DESKTOP</th>
              </tr>
              <tr>
                <td>Publish the user Remote Desktop on the Internet</td>
                <td>✓</td>
                <td>✓</td>
                <td>✓</td>
              </tr>
              <tr>
                <th>APPLICATION PUBLISHING</th>
              </tr>
              <tr>
                <td>Publish Applications on the Internet</td>
                <td>✓</td>
                <td>✓</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>Application Control ‘per user’ or ‘per group of users’</td>
                <td>✓</td>
                <td>✓</td>
                <td>✓</td>
              </tr>
              <tr>
                <th>PROTOCOL</th>
              </tr>
              <tr>
                <td>HTTP, HTTPS and RDP protocols compliance</td>
                <td>-</td>
                <td>✓</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>Compatible with any existing network</td>
                <td>-</td>
                <td>✓</td>
                <td>✓</td>
              </tr>
              <tr>
                <th>WEB PORTAL</th>
              </tr>
              <tr>
                <td>Web Access from Internet Explorer</td>
                <td>-</td>
                <td>✓</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>Starting RemoteApp outside the user Web Browser</td>
                <td>-</td>
                <td>✓</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>Web Application Portal</td>
                <td>-</td>
                <td>✓</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>
                  Connect from tablets, IPAD, Smartphone without having to
                  download any mobile application
                </td>
                <td>-</td>
                <td>-</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>
                  Does not require Internet Explorer. A session can be Started
                  from Safari, Firefox or Chrome.
                </td>
                <td>-</td>
                <td>-</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>
                  Full customization of the Web Portal and of the Web
                  Application Portal pages (wallpaper, logo...)
                </td>
                <td>-</td>
                <td>-</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>
                  Web Credentials to connect using a simple PIN code Without
                  Windows credentials.
                </td>
                <td>-</td>
                <td>-</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>
                  Does not need ActiveX or plug-ins to be downloaded and
                  installed on the user device
                </td>
                <td>-</td>
                <td>-</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>Remote printing even from Tablets or IPAD</td>
                <td>-</td>
                <td>-</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>
                  Starting multiple applications from one single Web Access
                  session
                </td>
                <td>-</td>
                <td>-</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>
                  Install, configure and deploy Web Access architecture in less
                  than a day
                </td>
                <td>-</td>
                <td>-</td>
                <td>✓</td>
              </tr>
              <tr>
                <th>SIMPLIFIED LICENSING MODEL</th>
              </tr>
              <tr>
                <td>Concurrent users license</td>
                <td>-</td>
                <td>-</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>
                  Enable Multiple sessions with the same login on all Windows
                  versions including W7, W8, W10, W11
                </td>
                <td>-</td>
                <td>-</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>Does not require RDS CALs</td>
                <td>-</td>
                <td>-</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>Run as server on W7, W8, W10 or 2016,2019</td>
                <td>-</td>
                <td>-</td>
                <td>✓</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ComparisonSheet;
