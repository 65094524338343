import React from "react";
import vissionImage from "../../assets/vission1.png";
import './vision.css';
function Vision() {
  return (
    <div className="vision-container" id="visionId">
      <div className="section vision-section">
        <h2>Our Vision</h2>

        <p>
          Empowering Seamless Connectivity: Our vision is to be the premier
          provider of Remote Desktop Services, redefining accessibility and
          collaboration in the digital landscape. We aim to deliver secure,
          efficient, and innovative solutions, fostering a world where
          businesses and individuals effortlessly connect, collaborate, and
          thrive through our cutting-edge IT services.
        </p>
        <img src={vissionImage} alt="Vision" />
      </div>
    </div>
  );
}

export default Vision;
