import React from "react";
import "./features.css";
import webAccess from "../../assets/webAccess.png";
function Features() {
  return (
    <div className="feature__container" id="featuresId">
      <div class="main-about">
        <div className="main__about_breaf">
          <h4>Connect App Features:</h4>
          <hr />
          <hr />

          <h2>
            The premier tool for deploying your application on the web and
            accessing your projects online with optimal efficiency
          </h2>

          <p>
            A perfect cost-effective alternative to Citrix &amp;RDS that works
            efficiently and seamlessly between your Server and remote device.
            Connect App can work on any Windows versions such as Windows XP, 7,
            8, 10 Pro or Windows Server 2008, 2012, 2016, 2019 – 32 or 64 bit
            Operating Systems. Following are some of the features of Connect
            App.
          </p>
        </div>
        <img src={webAccess} alt="" />
      </div>

      {/* feature box Application */}
      <div className="feature_container">
        <div class="features_box ">
          <div class="time_line_icon">
            <i class="fas fa-universal-access"></i>
          </div>
          <h2>Application level access</h2>
          <p>
            Connect App can provide the Application level access to the user.
            The application will be directly get opened from the Connect App
            floating panel. This will help the administrator to have better
            control from Server administration point of view.
          </p>
          <p>
            Connect App accesses the remote server, so smoothly that the
            application is displayed as a local user on the desktop and not a
            remote session. It looks like the application is rung on his own
            computer.
          </p>
        </div>
        {/* features desktop */}
        <div class="features_box">
          <div class="time_line_icon">
            <i class="fas fa-desktop"></i>
          </div>
          <h2>Desktop Access</h2>
          <p>
            Just like RDS CALs, the user gets the system access on real-time
            with Connect App.It can be installed on any Windows platforms such
            as Windows7, Windows8, Windows10 (Pro editions), or Windows Server
            Operating Systems (2008 to 2019. The user has facility to map local
            printers, local ports, local discs, remote FX, Dual screen etc.
          </p>
        </div>
      </div>
      {/* print on local feature box */}
      <div class="features__box__Print">
        <div class="time_line_icon">
          <i class="fas fa-print"></i>
        </div>
        <h2>Print on the Local Printer</h2>
        <p>
          Connect App provides ‘Universal Printer’ with the help of which, the
          user can take the printouts on the local Printer connected to the
          local desktop. Who you give the print commend in the application on
          Remote Server, the Universal Printer converts the print into PDF file
          which is pushed through the session to the local computer and gets
          printed on the local Printer! That is why, the printing happens
          without the local drives or ports getting mapped to the Server.
        </p>
      </div>
      {/* features other */}
      <div class="features__box__other">
        <div class="time_line_icon">
          <i class="fas fa-money-bill"></i>
        </div>
        <h2>Other benefits</h2>
        <ol type="I">
          <li>
            Connect App provides Active Directory support because of which the
            users can be configured to access Applications based on Active
            Directory user groups on the server.
          </li>
          <li>
            Easy to User Administrator Tool gives facility to configure
            Configuration Tools and Windows Features in a Control Panel.
          </li>
          <li>
            Lowest Cost RDS tool available in the world. Connect App offers the
            lowest cost as compared to Citrix or Microsoft RDS licenses.
          </li>
          <li>There is no need to purchase </li>

          <li>
            Provides support for 32 bit colors, dual-screen, bi-directional
            sound, USB devices and much more
          </li>
          <li>Enhanced AdminTool simplifies the server management process</li>
          <li>Advanced Application Control – User-wise or User-group-wise</li>
          <li>
            Fast file transfer between the user PC and the Connect App server
          </li>
          <li>
            Load balancing and failover for an unlimited number of servers
            within one farm
          </li>
          <li>Secured Server Access &amp; Communication </li>
          <li>Secured Web Access with port forwarding over HTTP and HTTPS</li>
          <li>
            HTML5 client enabling connection from IPAD and Android tablets
          </li>
        </ol>
      </div>
     
    </div>
  );
}

export default Features;
