import React from 'react';
import ReactDOM from 'react-dom/client';
// import App from './App.jsx'
import './index.css'
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Layout from './containers/Layout.jsx';
import AboutUs from './containers/aboutUs/AboutUs.jsx';
import Services from './containers/services/Services.jsx';
import ContactUs from './containers/ContactUs/ContactUsForm.jsx';
import MissionVisionPage from './containers/mission/MissionVisionPage.jsx';
import Vision from './containers/Vision/Vision.jsx';
import Features from './containers/ConnectApp/Features.jsx';
import Header from './containers/header/Header.jsx';
import Overview from './containers/ConnectApp/Overview.jsx';
import ComparisonSheet from './containers/ConnectApp/ComparisonSheet.jsx';
import Home from './containers/Home/Home.jsx';
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from '@vercel/speed-insights/react';
const router = createBrowserRouter([
  {
    path:'/',
    element : <Layout/>,
    children: [
      {
        path :"home",
        element :<Home/>

      },
    
      {
        path:"about",
        element :<AboutUs/>
      },
      {
        path:"services",
        element :<Services/>
      },
      {
        path : "contactUs",
        element :<ContactUs/>
      },
      {
        path : "mission",
        element :<MissionVisionPage/>
      },
      {
        path :"vission",
        element :<Vision/>
      },
      {
        path :"features",
        element :<Features/>
      },
      {
        path :"connectApp",
        element : <Overview/>
      },
      {
        path :"comparisonSheet",
        element:<ComparisonSheet/>
      }



    ]
  }
])
ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
  <RouterProvider router={router}/>
  </React.StrictMode>,
)
// import React from 'react'

function App() {
  return (
    <div>
      <Analytics/>
      <SpeedInsights />
    </div>
  )
}

export default App;

