import React from "react";
import "./services.css";
import { IoServerSharp } from "react-icons/io5";
import { VscRemoteExplorer } from "react-icons/vsc";
import { AiOutlineCloudServer } from "react-icons/ai";
import { HiMiniComputerDesktop } from "react-icons/hi2";
import { MdOutlineSecurity } from "react-icons/md";
function Services() {
  return (
    <div className="service__container" id="serviceId">
      <div className="heading">
        <h1 className="heading">Our Services</h1>
      </div>
      <div className="col__12">
        <h4 className="service__paragraph">
          We are involved in providing the clients with I.T. solutions &
          services to the clients. Our clients appreciate us for the promptness
          and effectiveness of the offered services. Our services are provided
          to the clients in a qualitative manner to ensure their utmost
          satisfaction.
        </h4>
      </div>
      <div class="box__container">
        <div class="box">
          <div className="icons">
            <IoServerSharp />
          </div>
          <h2>Server Based Computing</h2>
          <p>
            Server-based computing (SBC) is an over-arching term refering to the
            technology by which applications are implemented, controlled,
            supported and functioned on the server instead of the client.
            Upgrading hardware, application deployment, backing up of data and
            technical support are simplified in a server- based environment. As
            PCs turn into terminals and may get replaced by much simpler, more
            cost- effective and more convenient-to-manage devices known as thin
            clients. SBC is also known as “thin client computing”.
          </p>
        </div>
        <div class="box">
          <div className="icons">
            <VscRemoteExplorer />
          </div>
          <h2>Remote Desktop Solutions</h2>
          <p>
            Remote desktop is a program or an operating system feature that
            allows the user to connect to a computer in another location, see
            that computer's desktop and interact with it as if it were local.
          </p>
        </div>
      </div>
      <div class="box__container">
        <div class="box">
          <div className="icons">
            <AiOutlineCloudServer />
          </div>
          <h2>Cloud Server Solution</h2>
          <p>
            Cloud Server- Understanding the variegated needs of the clients, we
            offer Cloud Servers to the clients that enables the patrons to
            indulge in resource sharing without any delay.
          </p>
          <p>
            Hosted Server- Catering to the varied hosting needs of the clients,
            we have been able to provide them with a flawless Hosted Server.
          </p>
        </div>
        <div class="box">
          <div className="icons">
            <HiMiniComputerDesktop />
          </div>
          <h2>Thin Client Solution</h2>
          <p>
            A thin client is a device reliant on a server for operation,
            providing only basic processing power, a display, keyboard, and
            mouse for server interaction. It lacks local data storage, making it
            feature-light. Hardware options include repurposed PCs, dedicated
            thin client devices, or inexpensive PCs with a thin client OS. Due
            to minimal processing requirements and prolonged usability (around 6
            years compared to 3 for traditional PCs), thin clients reduce
            capital expenses. Importantly, they significantly lower overhead
            costs in administration, maintenance, support, security, and
            installation compared to traditional PCs.
          </p>
        </div>
      </div>
      <div class="box__container">
        <div class="box">
          <div className="icons">
            <svg
              stroke="currentColor"
              fill="none"
              stroke-width="2"
              viewBox="0 0 24 24"
              stroke-linecap="round"
              stroke-linejoin="round"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
              <circle cx="9" cy="7" r="4"></circle>
              <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
              <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
            </svg>
          </div>
          <h2>CRM Solution</h2>
          <p>
            Involved in providing the clients with ERP solutions, we have been
            able to organize various processes and organizational system in a
            synchronized manner.
          </p>
        </div>
        <div class="box">
          <div className="icons">
            <MdOutlineSecurity />
          </div>
          <h2>Firewall UTM Solutions</h2>
          <p>
            In computing, a firewall is a software or hardware-based network
            security system that controls the incoming and outgoing network
            traffic by analyzing the data packets and determining whether they
            should be allowed through or not, based on a rule set. A firewall
            establishes a barrier between a trusted, secure internal network and
            another network (e.g., the Internet) that is not assumed to be
            secure and trusted.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Services;
